import * as client_hooks from '../../../src/hooks.client.js';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84')
];

export const server_loads = [0];

export const dictionary = {
		"/": [13],
		"/(app)/@[slug]": [39,[2]],
		"/(app)/assets/g/lists/og1/[id]": [40,[6,7]],
		"/(app)/assets/g/trust-score/og1/[id]": [41,[6,8]],
		"/(app)/assets/g/u/[slug]/stack/og1": [42,[6,9]],
		"/(app)/brands": [43,[2]],
		"/(app)/brands/[slug]": [44,[2]],
		"/(app)/brands/[slug]/trustscore": [45,[2]],
		"/contact": [71],
		"/delete-account": [72],
		"/(app)/discover": [46,[2]],
		"/(app)/download": [47,[2]],
		"/(app)/(private)/feedback": [14,[2,3]],
		"/(app)/(private)/home": [15,[2,3]],
		"/(app)/(private)/internal/chat": [16,[2,3]],
		"/(app)/lists/[id]": [48,[2]],
		"/login": [~73],
		"/logout": [~74],
		"/lp/[slug]": [75],
		"/(app)/(private)/mocks/auth/signin": [17,[4]],
		"/(app)/(private)/mocks/auth/waitlist": [18,[4]],
		"/(app)/(private)/my/analyses/suppscore": [19,[2,3]],
		"/(app)/(private)/my/analyses/suppscore/complete": [20,[2,3]],
		"/(app)/(private)/my/analyses/suppscore/wizard": [21,[2,3]],
		"/(app)/(private)/my/analyses/suppscore/wizard/[slide]": [22,[2,3]],
		"/(app)/(private)/my/cabinet": [23,[2,3]],
		"/(app)/(private)/my/lists": [24,[2,3]],
		"/(app)/(private)/my/lists/[id]": [25,[2,3]],
		"/(app)/(private)/my/lists/[id]/edit": [26,[2,3]],
		"/(app)/(private)/my/protocols": [27,[2,3]],
		"/(app)/(private)/my/schedule/[[date]]": [28,[2,3]],
		"/(app)/(private)/my/stack/nutrients": [29,[2,3,5]],
		"/(app)/(private)/my/stack/products": [30,[2,3,5]],
		"/(app)/(private)/my/stack/protocols": [31,[2,3,5]],
		"/(app)/nutrients": [49,[2]],
		"/(app)/nutrients/popular": [51,[2]],
		"/(app)/nutrients/[slug]": [50,[2]],
		"/(app)/products": [52,[2]],
		"/(app)/(private)/products/edit": [32,[2,3]],
		"/(app)/products/popular": [54,[2]],
		"/(app)/(private)/products/submit/[[id]]": [33,[2,3]],
		"/(app)/products/[slug]": [53,[2]],
		"/(app)/profiles/popular": [57,[2]],
		"/(app)/profiles/[slug]": [55,[2]],
		"/(app)/profiles/[slug]/collections/quick-details": [56,[2]],
		"/(app)/(private)/profile": [34,[2,3]],
		"/(app)/(private)/profile/logs": [~35,[2,3]],
		"/(app)/protocols": [58,[2]],
		"/(app)/protocols/[slug]": [59,[2]],
		"/(app)/protocols/[slug]/match-confirm": [60,[2]],
		"/(app)/protocols/[slug]/select-products": [61,[2]],
		"/(app)/(private)/saves": [36,[2,3]],
		"/(app)/scanner": [62,[2]],
		"/sentry/example": [76],
		"/(app)/(private)/settings": [37,[2,3]],
		"/(app)/(private)/settings/profile": [38,[2,3]],
		"/signup": [~77],
		"/(app)/stacks/users/[slug]": [63,[2,10]],
		"/(app)/stacks/users/[slug]/nutrients": [64,[2,10]],
		"/(app)/stacks/users/[slug]/products": [65,[2,10]],
		"/(app)/stacks/users/[slug]/protocols": [66,[2,10]],
		"/(app)/supplements": [67,[2]],
		"/(app)/supplements/popular": [69,[2]],
		"/(app)/supplements/[slug]": [68,[2]],
		"/(app)/trustscore": [70,[2]],
		"/waitlist": [78,[11]],
		"/waitlist/[waitlistId]": [79,[11,12]],
		"/waitlist/[waitlistId]/accepted": [80,[11,12]],
		"/waitlist/[waitlistId]/access_code": [81,[11,12]],
		"/waitlist/[waitlistId]/edit": [82,[11,12]],
		"/waitlist/[waitlistId]/registered": [83,[11,12]],
		"/waitlist/[waitlistId]/waiting": [84,[11,12]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.js';